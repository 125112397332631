.add-lead-from h4 {
  color: #000;
  text-align: center;
  font-size: 23px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 14px 0 14px;
}
.marginLeft{
  margin-left: 300px;
  margin-bottom: 10px;
}
.marginTop{
  margin-top: 10px;
}
.deleteButtons{
  display: flex;
  justify-content: space-around;
  
}
.deleteButtons .buttonss{
width: 100px !important;
  
}

.add-lead-from form .rupeefield  input {
  width: 90% !important;
  display: inline !important;
}
.add-lead-from p {
  color: #000;
  text-align: center;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.3;
  opacity: 0.6;
}
.spacebetween{
  display: flex;
  width: 100% !important;
  justify-content: space-between;
}
.add-lead-from form input {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--background-mode, #FFF);
  width: 100%;
  height: 43px;
  padding: 0 18px;
  font-size: 14px;
}
.add-lead-from form input[type="color"] {
 
  border: none;
  background: none;
  width: 60px;
  font-size: 0;
  border-left: 1px solid #DFDFDF;
  padding: 10px 18px;
  border-top: 1px solid #DFDFDF;
  border-bottom: 1px solid #DFDFDF;
  position:absolute;
  border-top-right-radius:0% !important; 
  border-bottom-right-radius:0% !important;

}
.add-lead-from form input[type="number"]::-webkit-outer-spin-button,
.add-lead-from form input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.hideArrow[type="number"]::-webkit-outer-spin-button,
.hideArrow[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.add-lead-from form .colortext {
 
  border: none;
  background: none;
  width: 10px;
  height: 10px;
  padding: 0;
  font-size: 0;
 
}
.add-lead-from form .colorspan {
 
 display: flex;
 align-items: center;
 
}
.add-lead-from form .colorspan input[type="text"] {
 
border-left: none;
text-align: center !important;

 
}
.add-lead-from form select {
  border-radius: 10px;
  border: 1px solid #DFDFDF;
  background: var(--background-mode, #FFF);
  width: 100%;
  height: 43px;
  padding: 0 18px;
  font-size: 14px;
  color: #717171;
}

.add-more {
  border-radius: 10px;
  border: 1px dashed #094E85;
  background: var(--background-mode, #FFF);
  text-align: center;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #094E85;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.06px;
}

.add-lead-from {
  padding: 0 15px;
 
}
.add-lead-from-page {
  padding: 0 15px;
  width: 40%;
    margin: auto;
}
.add-lead-from h1{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.add-lead-from form .from-group input[type="submit"] {
  background: #094E85;
  color: #fff;
  font-size: 17px;
}

.fieldStyle {
  margin-bottom: 10px;
}

.add-lead-from input[type="radio"] {
  width: 12px;
  height: 11px;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
}

.add-lead-from input[type="checkbox"] {
  width: 12px;
  height: 11px;
  padding: 0;
  margin-top: 7px;
  margin-bottom: 20px;
}

.buttonss {
  width: 100% !important;
  --bs-btn-hover-bg: #0b5ed7;
}

.statusbuttons{
  width: 100px;
}

.add-lead-from input[type=number]::-webkit-outer-spin-button,
.add-lead-from input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

/* Firefox */
.add-lead-from input[type=number] {
  -moz-appearance: textfield !important;
}

.error {
  color: red;
  font-size: 13px;
  margin-bottom: 0px;
}
.CouponCode {
  background: #4B80AC;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  margin: 15px 0;
}
.outerCoupon {
  display: flex;
  align-items: center;
  padding: 0 30px;
  position: relative;
  min-height: 134px;
}
.rightCoupon {
  width: 75%;
  padding-left: 30px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.rightCoupon span.tag {
  background: #FFE500;
  padding: 7px 13px;
  border-radius: 100px;
  float: left;
  margin: 9px 0;
}
.copyBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.copyBtn a {
  background: #81bcff;
  color: #fff;
  text-decoration: none;
  padding: 6px 13px;
  font-size: 13px;
  border-radius: 100px;
}
.leftCoupon {
  width: 23%;
  border-right: 4px dashed #FFFFFF;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 211px;
  justify-content: center;
  position: relative;
  align-items: flex-start;
  padding-left: 16px;
}
.leftCoupon h4 {
  color: #fff;
  transform: rotate(-90deg);
  position: absolute;
  left: -19px;
  bottom: 36%;
  font-size: 26px;
  font-weight: 700;
}
.rightCoupon h4 {
  font-size: 23px;
  color: #fff;
}
.leftCoupon span {
  font-size: 19px;
  color: #fff;
  text-align: center;
  margin: 11px 0 0;
  line-height: 1;
}
.leftCoupon img {
  width: auto;
  height: auto;
  background: #81bcff;
  padding: 14px 7px;
  border-radius: 30px;
  position: relative;
  left: -4px;
}
.rightCoupon h5 span {
  font-size: 17px;
  font-weight: 500;
}
.rightCoupon h6 {
  color: #ffff;
  margin: 0;
}
.outerCoupon::before {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  position: absolute;
  content: "";
  border-radius: 100px;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
}
.outerCoupon::after {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  position: absolute;
  content: "";
  border-radius: 100px;
  right: -16px;
  top: 50%;
  transform: translateY(-50%);
}
.leftCoupon::before {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  position: absolute;
  content: "";
  border-radius: 100px;
  right: -16px;
  top: 0;
  transform: translateY(-50%);
}
.leftCoupon::after {
  width: 30px;
  height: 30px;
  display: flex;
  background: #fff;
  position: absolute;
  content: "";
  border-radius: 100px;
  right: -16px;
  bottom: -30px;
  transform: translateY(-50%);
}
.rightCoupon h5 {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
}
.leftCoupon h6 {
  color: #fff;
  transform: rotate(-90deg);
  position: absolute;
  left: -46px;
  bottom:40%;
  font-size: 13px;
}