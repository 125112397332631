.dashboard-page {
    display: flex;
    padding: 0;
    background: #F4F7F9;
}

.side-bar-left {
    width: 15%;
    height: 100vh;
    position: sticky;
    top: 0;

    border-radius: 10px;
}

.top-logo img {
    width: 100%;
    top: -4px;
    position: relative;
}

.top-logo {
    background: #094E85;
    padding: 7px 16px;

}

j .menu-list {
    padding: 10px 0 0;
}

.menu-list span {
    color: #40434c;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    padding: 14px 27px 16px;
    width: 100%;
    display: table;
}

.menu-list ul {
    padding: 0;
    margin: 0;
}

.menu-list ul li a {
    color: #96A5B8;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    text-decoration: none;
    width: 100%;
    display: table;
    padding-right: 0;
    padding: 15px 21px;
    padding-right: 0;
}

.menu-list ul li a img {
    margin-right: 8px;
}

.menu-list ul li.active a {
    background: #2880da0f;
    color: #094E85;
}

.menu-list ul.log-link {
    padding-top: 50px;
}

.menu-list ul.log-link li.logout a {
    color: #e00c0c;
}



.menu-list ul li.active a img {
    opacity: 1;
}

.menu-list ul li.logout a {
    color: red;
}

.menu-list ul li.logout a img {
    opacity: 1;
}


/* Side bar */
.side-bar-right {
    width: 85%;
    /* background: #fff; */
}

/* .inactive-sidebar .side-bar-right {
    width: 100%;
} */

.inactive-sidebar {
    background: red;
}

.top-bar {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 15px 35px;
}

.ham-brhr {
    margin-right: 20px;
}

.notification-bar {
    display: flex;
    align-items: center;
    /* margin-left: auto; */
}

.notify {
    position: relative;
    margin-right: 30px;
}

.notify span {
    position: absolute;
    width: 18px;
    height: 18px;
    background: #094E85;
    color: #fff;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    font-weight: 600;
    top: 0;
    right: -8px;
}

.agent-name {
    display: flex;
    align-items: center;
}

.cursor-pointer {
    cursor: pointer;
    list-style: none;
}

.agent-name img {
    margin-right: 9px;
}

.nnmelist h5 {
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 0.2;
    margin: 0;
    padding-top: 9px;
}

.nnmelist span {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.active {
    background: #2880da0f;
    color: #094E85;
}