.uploadInvoice {
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 0 10px 1px #ddd;
    padding: 25px;
    border-radius: 10px;
}
.oterCrency input {
    width: 100%;
    border: 0;
    font-size: 50px;
    text-align: center;
    outline: none;
}
.oterCrency input::-webkit-outer-spin-button,
.oterCrency input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.oterCrency span {
    text-align: center;
    width: 100%;
    display: table;
    font-size: 20px;
    color: deepskyblue;
    font-weight: 600;
}
.uploadInvoice .formGroup {
    margin-bottom: 13px;
    position: relative;
}
.uploadInvoice .formGroup input {
    width: 100%;
    height: 40px;
    padding: 0 12px;
    border-radius: 6px;
    border: 1px solid #ddd;
    color: #767676;
    padding-left: 35px;
}
.uploadInvoice input::-webkit-outer-spin-button,
.uploadInvoice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.oterCrency {
    margin-bottom: 25px;
}
.uploadInvoice .formGroup span {
    position: absolute;
    left: 12px;
    top: 9px;
}
.uploadInvoice .formGroup input[type="submit"] {
    background: #00c4ff;
    text-align: center;
    color: #fff;
}
.uploadInvoice .formGroup input[type="file"] {
    font-size: 0;
    border: 0;
    width: 0;
    padding: 10px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.typeFile
{
    height: 60px;
}
.typeFile span {
    background: #000;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0 !IMPORTANT;
    top: 0 !important;
    padding: 0;
    pointer-events: none;
}