.outtre-pad {
    padding: 0 35px;
}

.iconmargin {
    margin-right: 5px
}

.flexGraph {
    display: flex;
    justify-content: space-around;
}

/* .searchFilter{
    width: 100px;
} */
.graphInput {
    width: 30%
}

.heaing-filter h3 {
    color: #222B45;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin: 0;
}

.heaing-filter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 25px 0px 20px;
}

.red {
    background: #F70738 !important;
}

.filter-left span {
    color: #96A5B8;
    text-align: right;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    margin-left: 14px;
}

.filter-left select {
    color: #222B45;
    text-align: left;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    border: 0;
    background: transparent;
    outline: none;
    padding-right: 8px;
}

.filter-left button {
    background: transparent;
    border: 0;
    border-radius: 7px;
    border: 1px solid rgba(123, 145, 176, 0.24);
    outline: none;
    color: #222B45;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 9px;
}

.filter-left button img {
    margin-right: 6px;
}

.table-data table tr td span.kd {
    color: #3A78F2;
}

.table-data table tr td span.sc-btn.pending {
    border: 1px solid #FEBF00;
    color: #FEBF00;
}

.table-data table tr td span.sc-btn.faied {
    border: 1px solid #FD080F;
    color: #FD080F;
}

.table-data table tr td span.sc-btn {
    border-radius: 4px;
    border: 1px solid #64D4B0;
    background: var(--background-mode, #FFF);
    color: #64D4B0;
    padding: 12px 19px;
    min-width: 100px;
    display: flex;
    float: left;
    text-align: center;
    justify-content: center;
}

.grn {
    background: #00b378 !important;
}

.bl-shd {
    background: #094E85;
    color: #fff;
    padding: 5px 23px;
    border-radius: 5px;
    font-size: 13px;
    display: flex;
    justify-content: flex-start;
    float: left;
}

.cursor-pointer {
    cursor: pointer;
    list-style: none;
}

.description {
    margin-top: 15px;
}

.table-data {
    background: #fff;
    border-radius: 7px;
    padding: 30px 0;
}

.table-data table {
    width: 100%;
}

.table-data table tr th {
    /* color: #96A5B8; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 15px 25px;
    border-bottom: 1px solid #ddd;
}

.table-data table tr td img {
    margin-right: 8px;
}

.table-data table tr td {
    color: #222B45;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    padding: 15px 25px;
    border-bottom: 1px solid #ddd;
}

.table-data table tr:last-child td {
    border: 0;
}

.property-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px 10px;
}

.table-data h3 {
    padding: 0 25px;
}

.property-head ul li {
    margin-right: 40px;
}

.activeTab {
    color: #094E85;
    background-color: transparent;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    border-bottom: 3px solid #094E85;
    border-radius: 0 !important;
    padding: 0 4px;
}

.tab {
    color: #AFAFAF;
    background-color: transparent;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 0 4px;
}

.filter-pro button {
    border: 0;
    border-radius: 7px;
    border: 1px solid rgba(123, 145, 176, 0.24);
    outline: none;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    padding: 8px 9px;
}

.filter-pro button img {
    margin-right: 6px;
}

.add-lead {
    background: #0a273f;
    color: #fff;
    margin-left: 5px;
}

.tddropdown button {
    --bs-btn-color: none !important;
    --bs-btn-bg: none;
    --bs-btn-border-color: none;
    --bs-btn-hover-bg: none;
    --bs-btn-hover-border-color: none;
    --bs-btn-hover-color: none;
    --bs-btn-focus-shadow-rgb: none;
    /* --bs-btn-active-color: #fff; */
    --bs-btn-active-bg: none;
    --bs-btn-active-border-color: none;
    --bs-btn-active-shadow: none;
    --bs-btn-disabled-color: none;
    --bs-btn-disabled-bg: none;
    --bs-btn-disabled-border-color: none;
    --bs-btn-hover-color: none;
    --bs-btn-active-color: black
}