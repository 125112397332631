.light-side-bar {
    background: #fff;
}

/* .light-side-bar ul li a {
    color: #4d4d4d !important;
} */

.light-side-bar ul li a img {
    opacity: 0.5;
}

.light-theme-table {
    background: #fff;
}

.light-theme-table table tr th {
    color: #96A5B8;
}

.light-theme-table table tr td {
    color: #222B45;
}

.light-theme-table table tr:nth-child(2n) td {
    background: white;
}

.switch-theme {
    margin-left: auto;
    padding-right: 24px;
    color: #fff;
}

.switch-theme label {
    font-size: 14px;
    color: #000;
}

.undefined {
    border-radius: 10px;
}

.darktopbar img {
    cursor: pointer;
}

.undefined input,
.undefined button {
    background: aliceblue;
    border: none;
    padding: 10px;
}

.inactive-sidebar .light-side-bar {
    width: 25%;
    transform: translateX(-100%);
    transition: 0.35s;
    position: absolute;
    top: 0;
    left: -25%;
    bottom: 0;
}

.inactive-sidebar .right-side-bar {
    width: 100%;
    transition: 0.35s;
}


.active-sidebar .light-side-bar {
    transform: translateX(0);
    left: 0;
    overflow: auto;
    transition: 0.35s;
}

.switch-theme .form-switch .form-check-input:checked {
    background-color: #0a273f;
    border-color: white;
}



.dark {
    background: #1c2039;
}

.dark .darktopbar {
    background: #262b4a;
}

.dark .light-side-bar {
    background: #262b4a;
}

.dark .light-side-bar span,
.dark .light-side-bar ul li a {
    color: #fff !important;
}

.dark .light-side-bar ul li a img {
    opacity: 1;
    filter: opacity(0.5) drop-shadow(0 0 0 #fff);
}

.dark .light-theme-table {
    background: #262b4a;
}

.dark .light-theme-table h3 {
    color: #fff;
}

.dark .light-theme-table button {
    background: #1c2039;
}

.dark .light-theme-table table tr th {
    color: #fff;
    background: #1c2039;
    border: 0;
}

.dark .light-theme-table table tr:nth-child(2n) td {
    background: #1c2039;
}

.dark .light-theme-table table tr td {
    color: #fff;
}

.dark .light-theme-table table tr td:first-child img {
    filter: opacity(0.5) drop-shadow(0 0 0 #fff);
}

.dark .darkPagination {
    background-color: #262b4a;
}

.dark .darkPagination li {
    background: #1c2039 !important;
    color: #fff;
}

.dark .darkPagination li a svg path {
    fill: #fff;
}

.University .UK span:last-child .active_manage {
    list-style: none;
    display: flex;
    gap: 20px;
}

.University .UK span:last-child .active_manage li {
    background: #094e85;
    border: none;
    list-style: none;
    display: flex;
    gap: 20px;
    padding: 4px 20px;
    border-radius: 5px;
}

.University .UK span:last-child .active_manage li a {
    color: #fff;
    text-decoration: none;
}

.University .UK span:last-child label button {
    background: #094e85;
    border: none;
    color: #fff;
    display: flex;
    justify-content: space-between;
    text-align: left;
    padding: 15px 10px;
    border-radius: 5px;
}

.University .UK span:last-child label button span p {
    margin-bottom: 0;
    color: #cbcbcb;
}

.University .UK span:last-child .active_manage li:last-child {
    background: radial-gradient(circle at 14% 0%, rgb(246, 208, 46) 0%, rgb(198, 127, 1) 96%);
}