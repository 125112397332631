.login-page {
    height: 100vh;
}

.login-page .row {
    margin: 0;
}

.login-page .col-md-6 {
    padding: 0;
}

.login-right h3 {
    color: var(--neutral-white, #FFF);
    text-align: center;
    font-family: Poppins;
    font-size: 33px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    max-width: 60%;
}

.login-right {
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    padding: 53px 130px 60px;
    background: linear-gradient(210deg, #000 14.52%, #094E85 90.74%);
}

.login-right img {
    margin-bottom: 47px;
}

.login-right h3 {
    color: #FFF;
    text-align: center;
    font-family: Urbanist;
    font-size: 55px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 15px;
}

.login-right p {
    color: #FFF;
    max-width: 81%;
    font-family: Open Sans;
    font-size: 48px;
    font-style: italic;
    font-weight: 300;
    line-height: 134%;
}

.login-right p span {
    opacity: 0.5;
}


.login-left {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0;
    max-width: 450px;
    margin: 0 auto;
}

.login-left h2 {
    color: #094E85;

    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    margin: 0px 0 0;
}

.login-left div#pills-tabContent {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: auto;
}

.login-left h5 {
    color: var(--neutral-dark-grey, #8C91A2);
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
}

.login-left h5 a {
    color: #699198;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    text-decoration: none;
}

.login-left div#pills-tabContent.regiseter-tab {
    padding: 50px 0 40px;
    align-items: flex-start;
}

.login-left form input[type="submit"],
.login-left form input[type="button"] {
    border-radius: 8px;
    background: #094E85;
    width: 100%;
    color: var(--light-text-link-color-white, #FFF);
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    letter-spacing: 0.09px;
    border: 0;
    height: 56px;
    margin-top: 20px;
}

.login-left ul li button {
    color: #565656;
    text-align: center;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    outline: none;
}

.login-left ul li button.active::before {
    border-radius: 50px;
    background: #094E85;
    width: 31px;
    height: 4px;
    content: "";
    position: absolute;
    bottom: -5px;
}

.login-left ul li button.active {
    color: #094E85 !important;
    background: transparent !important;
    font-weight: 700 !important;
    position: relative;
}

.login-left ul li {
    margin-left: 20px;
}

.forget-ass a {
    color: #094E85;
    text-align: right;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: auto;
    font-size: 15px;
    text-decoration: none;
    margin-top: 0;
}

.login-left form .form-group {
    margin-bottom: 20px;
    position: relative;
}

.login-left form .form-group span {
    position: absolute;
    right: 20px;
    top: 39px;
}

.login-left form .form-group {
    margin-bottom: 20px;
}

.login-left form {
    padding-top: 36px;
    width: 100%;
}

.login-left form input[type="text"],
.login-left form input[type="password"],
.login-left form input[type="number"] {
    height: 56px;
    padding: 0 24px;
    outline: none;
    border-radius: 8px;
    border: 1px solid #e2e2e261;
    background: #80808017;
    width: 100%;
    font-size: 14px;
}

.login-left div#pills-tabContent .tab-pane {
    width: 100%;
}

.login-left form label {
    color: #333333;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: 0.08px;
    margin-bottom: 6px;
}

.forget-ass {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-left .form-group.forget-ass {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.login-left ul {
    border-bottom: 1px solid #E9F1FB;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding: 30px 130px 5px;
}

.login-left form .form-group.otp-reset {
    display: flex;
}

.login-left form .form-group.otp-reset input {
    text-align: center;
    margin: 0 10px;
}

.login-left form .form-group p {
    font-size: 13px;
    color: #c1c1c1;
}

.login-left .upload-image span {
    border-radius: 4px;
    border: 1px dashed #094E85;
    background: #FFF;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative !important;
    justify-content: center;
    top: 0 !important;
    left: 0;
}

.login-left .upload-image span i {
    font-size: 22px;
    color: #094E85;
}

.switch-btn label.form-check-label {
    color: #000000ad;
    font-feature-settings: 'clig' off, 'liga' off;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.3px;
}