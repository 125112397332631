body {
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  margin: 0 auto;
}

/* Global style to hide arrow in Dropdown */

.dropdown-toggle::after {
  display: none !important;
}