.paginationContainer {
    display: flex;
    justify-content: space-around;
    background-color: #eef5fc;
    color: white;
    padding: 10px;
    text-align: center;
    border-radius: 30px;
    font-weight: 800;
    font-size: large;
    margin: 20px auto;
    width: fit-content;
}

.page {
    list-style: none;
    color: #094E85;
    background-color: white;
    border-radius: 4px;
    font-weight: 500;
    margin: 0px 10px
}

.pageLink {
    text-decoration: none;
    padding: 6px 15px;
    display: flex;
    justify-content: center;
    align-items: center;  
}

.selected {
    background-color: #0a273f !important;
    border-collapse: separate;
    border-spacing: 15px;
    border-radius: 100px;
}
.paginationContainer svg path{
fill: #09273F;
}
.selected a
{
    color: #fff;
}

.paginateButton {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    width: 40px;
    height: 40px;
    border-radius: 50%;
}

.paginateLink {
    text-decoration: none;
    font-weight: 200;

}